import { Component, Inject } from '@angular/core';
// Material
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'rename-conversation',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    CommonModule,
  ],
  templateUrl: './rename-conversation.component.html',
  styleUrl: './rename-conversation.component.sass',
})
export class RenameConversationComponent {
  // Variables
  conversationName: string = '';

  constructor(
    public dialogRef: MatDialogRef<RenameConversationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // console.log(data);
    this.conversationName = data;
  }

  // Close dialog
  closeDialog(): void {
    this.dialogRef.close();
  }

  // Rename conversation
  renameConversation(): void {
    this.dialogRef.close(this.conversationName);
  }

  // Disable rename button
  disableRenameButton(): boolean {
    const conversationName = this.conversationName.trim();
    return conversationName === '';
  }
}
