import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { eRRoles as RaRole, eRRoles } from '../enums/eRRoles';
import { eRFeatures } from '../enums/er-features';
import { iRFUser } from '../models/fb/fb-query.interface';
import { iRUser } from '../models/fb/user-details.interface';

// Company Interface
export interface iCompany {
  companyName: string;
  id: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  userDetails?: iRUser;
  userId?: string;
  selectedRole?: RaRole;
  isOverlay = false;
  userCompany?: iCompany;
  chatUserSelected = new Subject<iRFUser>();
  chatSelection$ = this.chatUserSelected.asObservable();
  jimSubscription: boolean = false;

  clear() {
    this.isLoggedIn = false;
    this.userDetails = undefined;
    this.userId = undefined;
    this.selectedRole = undefined;
    this.isOverlay = false;
  }

  get isSelMa() {
    return this.selectedRole === eRRoles.manager;
  }
  get isSelOw() {
    return this.selectedRole === eRRoles.owner;
  }
  get isSelMe() {
    return this.selectedRole === eRRoles.mechanic;
  }
  get isSelUs() {
    return this.selectedRole === eRRoles.user;
  }
  get isSelAd() {
    return this.selectedRole === eRRoles.admin;
  }
  get isMa() {
    return this.userDetails?.role === eRRoles.manager;
  }
  get isOw() {
    return this.userDetails?.role === eRRoles.owner;
  }
  get isMe() {
    return this.userDetails?.role === eRRoles.mechanic;
  }
  get isUs() {
    return this.userDetails?.role === eRRoles.user;
  }
  get isAd() {
    return this.userDetails?.role === eRRoles.admin || this.userDetails?.admin;
  }

  showOverlay() {
    this.isOverlay = true;
  }
  hideOverlay() {
    this.isOverlay = false;
  }

  features = [
    {
      name: eRFeatures.fh,
      image: 'assets/features/fleet_health.png',
      roles: [RaRole.manager, RaRole.owner],
    },
    {
      name: eRFeatures.stat,
      image: 'assets/features/statistics.png',
      roles: [RaRole.manager, RaRole.owner],
    },
    {
      name: eRFeatures.users,
      image: 'assets/features/users.png',
      roles: [RaRole.manager, RaRole.owner, RaRole.admin],
    },
    {
      name: eRFeatures.insp,
      image: 'assets/features/trip-inspection.png',
      roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user],
    },
    {
      name: eRFeatures.rh,
      image: 'assets/features/request-history.png',
      roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user],
    },
    {
      name: eRFeatures.diagnostics,
      image: 'assets/features/diagnostic-tile.png',
      roles: [RaRole.manager, RaRole.owner, RaRole.mechanic],
    },
    {
      name: eRFeatures.rep,
      image: 'assets/features/repairs.png',
      roles: [RaRole.mechanic],
    },
    {
      name: eRFeatures.sr,
      image: 'assets/features/service-records.png',
      roles: [RaRole.manager, RaRole.owner, RaRole.mechanic],
    },
    {
      name: eRFeatures.est,
      image: 'assets/features/estimate.png',
      roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user],
    },
    {
      name: eRFeatures.rem,
      image: 'assets/features/reminders.png',
      roles: [RaRole.manager, RaRole.owner, RaRole.mechanic],
    },
    {
      name: eRFeatures.cal,
      image: 'assets/features/calendar-reminder.png',
      roles: [RaRole.mechanic],
    },
    {
      name: eRFeatures.ts,
      image: 'assets/features/timesheet.png',
      roles: [RaRole.manager, RaRole.owner, RaRole.user],
    },
    {
      name: eRFeatures.inv,
      image: 'assets/features/service-records.png',
      roles: [RaRole.user],
    },
    {
      name: eRFeatures.vid,
      image: 'assets/features/video-training.png',
      roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user],
    },
    {
      name: eRFeatures.mv,
      image: 'assets/features/manage-vehicles.png',
      roles: [RaRole.manager, RaRole.owner],
    },
    {
      name: eRFeatures.it,
      image: 'assets/features/pre-post-inspection-titles.png',
      roles: [RaRole.manager, RaRole.owner],
    },
    {
      name: eRFeatures.dt,
      image: 'assets/features/trip-tiles.png',
      roles: [RaRole.manager, RaRole.owner],
    },
    {
      name: eRFeatures.comp,
      image: 'assets/features/companies.png',
      roles: [RaRole.admin],
    },
    {
      name: eRFeatures.company,
      image: 'assets/features/companies.png',
      roles: [RaRole.manager],
    },
    {
      name: eRFeatures.tut,
      image: 'assets/features/tutorials.png',
      roles: [RaRole.admin],
    },
    {
      name: eRFeatures.remp,
      image: 'assets/features/reminder-profiles.png',
      roles: [RaRole.manager, RaRole.owner, RaRole.mechanic],
    },
    {
      name: eRFeatures.sub,
      image: 'assets/features/subscriptions.png',
      roles: [RaRole.manager, RaRole.owner],
    },
    {
      name: eRFeatures.gps,
      image: 'assets/features/map.png',
      roles: [RaRole.manager, RaRole.owner, RaRole.mechanic],
    },
    {
      name: eRFeatures.obd,
      image: 'assets/features/obd2.png',
      roles: [RaRole.manager, RaRole.owner],
    },
    {
      name: eRFeatures.help,
      image: 'assets/features/call-for-help.png',
      roles: [RaRole.user],
    },
    {
      name: eRFeatures.rental,
      image: 'assets/features/rental.png',
      roles: [RaRole.manager, RaRole.owner, RaRole.user],
    },
    {
      name: eRFeatures.maint,
      image: 'assets/features/maintenance_profile.png',
      roles: [RaRole.manager, RaRole.owner],
    },
    {
      name: eRFeatures.pms,
      image: 'assets/features/pms.svg',
      roles: [RaRole.manager, RaRole.owner],
    },
  ];

  // Side Menu Features having name, icon name and roles
  sideMenuFeatures = [
    { name: eRFeatures.fh, icon: 'local_hospital', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.mv, icon: 'local_shipping', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.maint, icon: 'build', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.pms, icon: 'av_timer', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.stat, icon: 'bar_chart', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.insp, icon: 'search', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user] },
    { name: eRFeatures.diagnostics, icon: 'assignment', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic] },
    { name: eRFeatures.rh, icon: 'history', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user] },
    // { name: eRFeatures.rep, icon: 'build', roles: [RaRole.mechanic] },
    { name: eRFeatures.sr, icon: 'history', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic] },
    { name: eRFeatures.est, icon: 'monetization_on', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user] },
    // { name: eRFeatures.rem, icon: 'av_timer', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic] },
    // { name: eRFeatures.cal, icon: 'calendar_today', roles: [RaRole.mechanic] },
    { name: eRFeatures.ts, icon: 'timeline', roles: [RaRole.manager, RaRole.owner, RaRole.user] },
    { name: eRFeatures.inv, icon: 'receipt', roles: [RaRole.user] },
    // { name: eRFeatures.remp, icon: 'build', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic] },
    // { name: eRFeatures.gps, icon: 'map', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic] },
    { name: eRFeatures.obd, icon: 'gps_fixed', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.rental, icon: 'departure_board', roles: [RaRole.manager, RaRole.owner, RaRole.user] },
    { name: eRFeatures.sub, icon: 'subscriptions', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.it, icon: 'text_fields', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.dt, icon: 'text_fields', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.vid, icon: 'video_library', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user] },
    { name: eRFeatures.help, icon: 'phone_in_talk', roles: [RaRole.user] },
    { name: eRFeatures.comp, icon: 'domain', roles: [RaRole.admin] },
    { name: eRFeatures.users, icon: 'people', roles: [RaRole.manager, RaRole.owner, RaRole.admin] },
    { name: eRFeatures.tut, icon: 'help', roles: [RaRole.admin] },
  ];

  // Features Divided by Vehicle Management, Maintnenance, Reports, Administrative, Content, Settings
  vehicleManagementFeatures = [
    { name: eRFeatures.mv, icon: 'local_shipping', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.obd, icon: 'gps_fixed', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.rental, icon: 'departure_board', roles: [RaRole.manager, RaRole.owner, RaRole.user] },
  ];

  maintenanceFeatures = [
    { name: eRFeatures.fh, icon: 'local_hospital', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.maint, icon: 'build', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.pms, icon: 'av_timer', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.insp, icon: 'search', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user] },
    { name: eRFeatures.diagnostics, icon: 'assignment', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic] },
    { name: eRFeatures.sr, icon: 'history', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic] },
    { name: eRFeatures.est, icon: 'monetization_on', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user] },
  ];

  reportingFeatures = [
    { name: eRFeatures.stat, icon: 'receipt', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.ts, icon: 'timeline', roles: [RaRole.manager, RaRole.owner, RaRole.user] },
    { name: eRFeatures.rh, icon: 'history', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user] },
    { name: eRFeatures.inv, icon: 'receipt', roles: [RaRole.user] },
  ];

  administrativeFeatures = [
    { name: eRFeatures.users, icon: 'people', roles: [RaRole.manager, RaRole.owner, RaRole.admin] },
    { name: eRFeatures.comp, icon: 'domain', roles: [RaRole.admin] },
    { name: eRFeatures.sub, icon: 'subscriptions', roles: [RaRole.manager, RaRole.owner] },
  ];

  contentFeatures = [
    { name: eRFeatures.vid, icon: 'video_library', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user] },
    { name: eRFeatures.tut, icon: 'help', roles: [RaRole.admin] },
  ];

  settingsFeatures = [
    { name: eRFeatures.it, icon: 'text_fields', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.dt, icon: 'text_fields', roles: [RaRole.manager, RaRole.owner] },
  ];
}
