import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  // Companies
  companies: any;
  tokenCollectionId: string = '';

  constructor() { }

  // Set Companies
  setCompanies(companies: any) {
    this.companies = companies;
  }

  // Get Companies
  getCompanies() {
    return this.companies;
  }

  // Set Token Collection Id
  setTokenCollectionId(tokenCollectionId: string) {
    this.tokenCollectionId = tokenCollectionId;
  }

  // Get Token Collection Id
  getTokenCollectionId() {
    return this.tokenCollectionId;
  }
}
