import { Component, HostListener, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { INgxLoadingConfig, NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { Messaging, getMessaging, getToken, onMessage } from '@angular/fire/messaging';
import { environment } from '../environments/environment';
import { FirebaseService } from './shared/services/firebase.service';
import { SigninComponent } from './pages/auth/signin/signin.component';
import { eRTable } from './shared/enums/er-tables';
import { SigninService } from './pages/auth/signin/signin.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';
import { AiDialogComponent } from './shared/components/ai-dialog/ai-dialog.component';

// AI Assistant icon svg
const AI_ICON = `
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M323-160q-11 0-20.5-5.5T288-181l-78-139h58l40 80h92v-40h-68l-40-80H188l-57-100q-2-5-3.5-10t-1.5-10q0-4 5-20l57-100h104l40-80h68v-40h-92l-40 80h-58l78-139q5-10 14.5-15.5T323-800h97q17 0 28.5 11.5T460-760v160h-60l-40 40h100v120h-88l-40-80h-92l-40 40h108l40 80h112v200q0 17-11.5 28.5T420-160h-97Zm217 0q-17 0-28.5-11.5T500-200v-200h112l40-80h108l-40-40h-92l-40 80h-88v-120h100l-40-40h-60v-160q0-17 11.5-28.5T540-800h97q11 0 20.5 5.5T672-779l78 139h-58l-40-80h-92v40h68l40 80h104l57 100q2 5 3.5 10t1.5 10q0 4-5 20l-57 100H668l-40 80h-68v40h92l40-80h58l-78 139q-5 10-14.5 15.5T637-160h-97Z"/></svg>`;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    NgxLoadingModule,
    MatSnackBarModule,
    MatButtonModule,
    CdkDrag,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.sass'
})
export class AppComponent {
  // Inject services & variables
  auth = inject(AuthService);
  title = 'revitauto';
  private readonly _messaging = inject(Messaging);
  private fbs = inject(FirebaseService);
  private signinService = inject(SigninService);
  private snackBar = inject(MatSnackBar);
  private router = inject(Router);
  private dialog = inject(MatDialog);

  // Variables
  dialogOpen = false;
  dialogRef: any;

  public config: INgxLoadingConfig = {
    animationType: ngxLoadingAnimationTypes.chasingDots,
    fullScreenBackdrop: true,
    backdropBorderRadius: "4px",
    primaryColour: "#45047A",
    secondaryColour: "#cc3546",
    tertiaryColour: "#16b542",
  };

  constructor(iconiconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconiconRegistry.addSvgIconLiteral('ai-icon', sanitizer.bypassSecurityTrustHtml(AI_ICON));
  }

  get isOverlay() {
    return this.auth.isOverlay;
  }

  async ngOnInit() {
    this._onMessage();
  }

  titleNotif = 'af-notification';
  message: any = null;

  private _onMessage(): void {
    onMessage(this._messaging, {
      next: (payload) => {
        if (payload.notification && payload.notification.body) {
          const snack = this.snackBar.open(payload.notification.body, 'Notifications', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'end',
          });
          snack.onAction().subscribe(() => {
            // Redirect to notifications page
            this.router.navigate(['/notifications']);
          });
        }
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
      },
    });
  }

  // AI Assistant Dialog
  openAiDialog() {
    // Do not open dialog if it is already open
    if (this.dialogOpen) return;
    this.dialogOpen = true;
    const dialogRef = this.dialog.open(AiDialogComponent, {
      width: '40vw',
      height: '60vh',
      minWidth: '35vw',
      minHeight: '50vh',
      hasBackdrop: false,
      disableClose: true,
      position: {
        bottom: '5vh',
        left: '5vw',
      },
      panelClass: 'resizable-mat-dialog-panel'
    });
    this.dialogRef = dialogRef;
    dialogRef.afterClosed().subscribe(() => {
      this.dialogOpen = false;
    });
  }

  public closeDialog() {
    if (!this.dialogRef) return;
    this.dialogRef.close();
    this.dialogOpen = false;
    this.dialogRef = null;
  }

  // Remove tokens from fcmToken collection when the app is closed with @HostListener
  @HostListener('window:beforeunload', ['$event'])
  async onUnload($event: any) {
    const tokenCollId = this.signinService.getTokenCollectionId();
    await this.fbs.setDocById(eRTable.fcmToken, tokenCollId, { token: '' });
  }


}
