<div class="flex flex-col justify-between h-full" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container">
    <!-- Modal Bar -->
    <div class="flex justify-between items-center bg-violet-500 shadow-md shadow-violet-200 cursor-grab active:cursor-grabbing"
        cdkDragHandle>
        <div class="flex gap-[4px] px-[6px]">
            <mat-icon svgIcon="ai-icon"
                class="!flex items-center justify-center !text-[20px] !w-[24px] !h-[24px]"></mat-icon>
            <label class="text-white font-bold">JIM AI Assistant</label>
        </div>
        <div class="flex">
            @if(!fullscreen){
            <mat-icon (click)="toggleFullscreen()" matTooltip="Expand"
                class="!flex items-center justify-center !text-[20px] text-[var(--ra-gray)] !w-[24px] !h-[24px] cursor-pointer hover:text-white hover:bg-violet-400">
                fullscreen
            </mat-icon>
            } @else {
            <mat-icon (click)="toggleFullscreen()" matTooltip="Minimize"
                class="!flex items-center justify-center !text-[20px] text-[var(--ra-gray)] !w-[24px] !h-[24px] cursor-pointer hover:text-white hover:bg-violet-400">
                fullscreen_exit
            </mat-icon>
            }
            <mat-icon (click)="closeDialog()" matTooltip="Close"
                class="!flex items-center justify-center !text-[20px] text-[var(--ra-gray)] !w-[24px] !h-[24px] cursor-pointer hover:text-white hover:bg-red-400">close</mat-icon>
        </div>
    </div>
    <!-- /Modal Bar -->

    <!-- Modal Content -->
    <div class="flex h-full w-full overflow-hidden">
        <!-- Chats -->
        <div class="flex flex-col w-[30%] p-[6px] h-full shadow-md shadow-violet-500 bg-violet-100">
            <!-- New Conversation Button -->
            <div (click)="newConversation()"
                class="flex items-center justify-center gap-[6px] p-[4px] bg-violet-300 !text-white !w-full !h-[40px] rounded-md hover:font-bold cursor-pointer hover:bg-violet-400 hover:animate-pulse">
                <mat-icon class="!min-w-[20px]">add</mat-icon>
                <span class="cursor-pointer">New Conversation</span>
            </div>
            <!-- /New Conversation Button -->

            <!-- Conversations Containers -->
            <div class="flex flex-col gap-[4px] overflow-y-auto">
                <!-- Show only the 5 most recent -->
                <label class="text-violet-500 font-bold">Conversations</label>
                @if(conversations.length > 0){
                @for(conversation of conversations.slice(0, 5); track $index) {
                <div class="flex items-center justify-between p-[6px] rounded-md bg-violet-200 hover:bg-violet-300 cursor-pointer"
                    [ngClass]="{ 'bg-violet-500': conversation.id === selectedConversation?.id, '!text-white': conversation.id === selectedConversation?.id }"
                    (click)="selectConversation(conversation)" matTooltip="{{conversation.data.title}}"
                    matTooltipPosition="right">
                    <mat-icon [ngClass]="{ 'text-violet-500': conversation.id !== selectedConversation?.id }"
                        class="!flex items-center justify-center !text-[20px] !w-[20px] !h-[20px] !min-w-[20px]">chat</mat-icon>
                    <div class="flex justify-start w-[70%]">
                        <label [ngClass]="{ 'text-violet-500': conversation.id !== selectedConversation?.id }"
                            class="text-violet-500 cursor-pointer text-sm w-full h-[20px] overflow-x-hidden text-left overflow-ellipsis whitespace-nowrap">{{conversation.data.title}}</label>
                    </div>
                    <!-- Options -->
                    <mat-icon (click)="$event.stopPropagation(); $event.preventDefault();" [matMenuTriggerFor]="menu"
                        #menuTrigger [ngClass]="{ 'text-violet-500': conversation.id !== selectedConversation?.id }"
                        class="!flex items-center justify-center !text-[20px] !w-[20px] !h-[20px] !min-w-[20px] cursor-pointer rounded-[50%] hover:text-violet-200 hover:bg-violet-500">more_vert</mat-icon>
                    <mat-menu #menu="matMenu" class="menu">
                        <div (click)="renameConversation(conversation)"
                            class="flex cursor-pointer p-[4px] text-[var(--ra-grey)] hover:bg-violet-200">
                            Rename</div>
                        <div (click)="deleteConversation(conversation)"
                            class="flex cursor-pointer p-[4px] text-[var(--ra-grey)] hover:bg-violet-200">
                            Delete</div>
                    </mat-menu>
                </div>
                }
                <!-- Show More/Less dropdown like to show the rest if conversations.length > 5 -->
                @if(conversations.length > 5){
                <!-- Show More/Less Label with icon -->
                <div (click)="showMore = !showMore"
                    class="flex items-center justify-start gap-[6px] text-violet-500 !w-full hover:font-bold cursor-pointer hover:animate-pulse">
                    <span class="cursor-pointer">{{showMore ? 'Show Less' : 'Show More'}}</span>
                    <mat-icon>{{showMore ? 'expand_less' : 'expand_more'}}</mat-icon>
                </div>
                <!-- /Show More/Less dropdown -->
                <!-- Show the rest of the conversations if showMore is true -->
                @if(showMore){
                @for(conversation of conversations.slice(5); track $index) {
                <div class="flex items-center justify-between p-[6px] rounded-md bg-violet-200 hover:bg-violet-300 cursor-pointer"
                    [ngClass]="{ 'bg-violet-500': conversation.id === selectedConversation?.id, '!text-white': conversation.id === selectedConversation?.id }"
                    (click)="selectConversation(conversation)" matTooltip="{{conversation.data.title}}"
                    matTooltipPosition="right">
                    <mat-icon [ngClass]="{ 'text-violet-500': conversation.id !== selectedConversation?.id }"
                        class="!flex items-center justify-center !text-[20px] !w-[20px] !h-[20px] !min-w-[20px]">chat</mat-icon>
                    <div class="flex justify-start w-[70%]">
                        <label [ngClass]="{ 'text-violet-500': conversation.id !== selectedConversation?.id }"
                            class="text-violet-500 cursor-pointer text-sm w-full h-[20px] overflow-x-hidden text-left overflow-ellipsis whitespace-nowrap">{{conversation.data.title}}</label>
                    </div>
                    <!-- Options -->
                    <mat-icon (click)="$event.stopPropagation(); $event.preventDefault();" [matMenuTriggerFor]="menu"
                        #menuTrigger [ngClass]="{ 'text-violet-500': conversation.id !== selectedConversation?.id }"
                        class="!flex items-center justify-center !text-[20px] !w-[20px] !h-[20px] !min-w-[20px] cursor-pointer rounded-[50%] hover:text-violet-200 hover:bg-violet-500">more_vert</mat-icon>
                    <mat-menu #menu="matMenu" class="menu">
                        <div (click)="renameConversation(conversation)"
                            class="flex cursor-pointer p-[4px] text-[var(--ra-grey)] hover:bg-violet-200">
                            Rename</div>
                        <div (click)="deleteConversation(conversation)"
                            class="flex cursor-pointer p-[4px] text-[var(--ra-grey)] hover:bg-violet-200">
                            Delete</div>
                    </mat-menu>
                </div>
                }
                }
                }
                } @else {
                <div class="flex flex-col items-center justify-center h-full">
                    <mat-icon class="text-violet-500">chat</mat-icon>
                    <label class="text-[12px] text-violet-500 font-bold">No conversations yet!</label>
                </div>
                }

            </div>

        </div>
        <!-- /Chats -->
        <!-- Chat Window -->
        <div class="flex flex-col justify-between w-[70%] h-full overflow-y-auto overflow-x-hidden">
            @if(messages.length === 0){
            <div class="flex items-center justify-center h-full">
                <label class="text-lg text-violet-500 font-bold animate-pulse">Hi,
                    {{auths.userDetails?.fullname}}!</label>
            </div>
            }
            <div #messageContainer class="flex flex-col gap-[8px] overflow-y-auto p-[10px]">
                @for(message of messages; track $index) {
                <!-- User messages to the right and AI response to the left -->
                @if(message.sender === 'user'){
                <div class="flex justify-end">
                    <div
                        class="bg-violet-500 max-w-[70%] text-white p-[6px] rounded-tl-[10px] rounded-br-[10px] rounded-bl-[10px] shadow-md shadow-violet-200">
                        <p>{{message.message}}</p>
                    </div>
                </div>
                }
                @else {
                <div class="flex justify-start">
                    <!-- AI icon -->
                    <mat-icon svgIcon="ai-icon-violet"
                        class="!flex items-center justify-center !text-[20px] !w-[24px] !h-[24px]"></mat-icon>
                    <div
                        class="bg-violet-200 max-w-[90%] text-violet-500 p-[6px] rounded-tr-[10px] rounded-bl-[10px] rounded-br-[10px] shadow-md shadow-violet-200">
                        <div [innerHTML]="message.message | textToHtml"></div>
                    </div>
                </div>
                }
                }
                @if(isGenerating){
                <div class="flex items-center gap-[6px]">
                    <!-- Mat spinner with icon in center -->
                    <div class="relative spinner">
                        <mat-spinner diameter="30" class="absolute"></mat-spinner>
                        <!-- ai violet icon -->
                        <mat-icon svgIcon="ai-icon-violet"
                            class="absolute z-50 top-[3px] left-[3px] animate-pulse"></mat-icon>
                    </div>
                    <label class="text-violet-500 animate-pulse">Generating response...</label>
                </div>
                }
            </div>
            <!-- Input -->
            <div class="py-[2px] pl-[8px] pr-[6px]">
                <mat-form-field appearance="outline" class="ra-input-ta no-hint">
                    <textarea matInput [(ngModel)]="message" (keyup.enter)="sendMessage()" style="resize: none;"
                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="3"></textarea>
                    <mat-icon matSuffix (click)="sendMessage()"
                        class="cursor-pointer text-violet-500 !flex items-center justify-center !text-[20px] !w-[24px] !h-[24px] hover:text-violet-200">send</mat-icon>
                </mat-form-field>
            </div>
        </div>
        <!-- /Chat Window -->
    </div>
    <!-- /Modal Content -->

    <!-- Footer -->
    <div class="text-center bg-violet-500">
        <label class="text-xs text-white">Developed By &copy;Revit Auto - Powered By <a href="https://gemini.google.com"
                class="underline hover:font-bold">Gemini</a></label>
    </div>
    <!-- /Footer -->
</div>