<!-- Floating button cdkDrag on bottom left -->
<div class="h-[100vh] overflow-y-auto boundary">
    @if(auth.isLoggedIn && auth.jimSubscription){
    <button mat-fab matTooltip="JIM AI Assistant" (click)="openAiDialog()"
        class="!bg-violet-500 !absolute bottom-[6px] left-[6px] !w-[50px] !h-[50px] opacity-50 hover:opacity-100 z-[9999]"
        cdkDrag cdkDragBoundary=".boundary">
        <mat-icon svgIcon="ai-icon"></mat-icon>
    </button>
    }
    <router-outlet />
</div>
<ngx-loading [show]="isOverlay" [config]="config"></ngx-loading>