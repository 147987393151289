<div class="flex flex-col">
    <!-- Title and close icon -->
    <div class="flex justify-between p-[4px] bg-violet-500">
        <label class="text-white font-bold">Delete Conversation</label>
        <mat-icon class="cursor-pointer text-white hover:text-violet-200" (click)="closeDialog()">close</mat-icon>
    </div>
    <!-- Content -->
    <div class="flex flex-col p-[10px] gap-[10px]">
        <!-- Confirmation -->
        <p class="text-sm text-[var(--ra-grey)]">
            Are you sure you want to delete this conversation?
        </p>

        <!-- Buttons -->
        <div class="flex justify-end gap-[10px]">
            <button mat-raised-button class="!bg-white !text-violet-500 !rounded-md"
                (click)="closeDialog()">Cancel</button>
            <button mat-raised-button class="!bg-violet-500 !text-white !rounded-md"
                (click)="deleteConversation()">Delete</button>
        </div>
    </div>
</div>