import { Component, Inject } from '@angular/core';
// Material
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'delete-conversation',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    CommonModule
  ],
  templateUrl: './delete-conversation.component.html',
  styleUrl: './delete-conversation.component.sass'
})
export class DeleteConversationComponent {

  // Constructor
  constructor(
    public dialogRef: MatDialogRef<DeleteConversationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  // Close dialog
  closeDialog(): void {
    this.dialogRef.close();
  }

  // Delete conversation
  deleteConversation(): void {
    this.dialogRef.close(true);
  }
}
