import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
// Ngx Mask
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideFirebaseApp(() => initializeApp({
      projectId: "revi-auto",
      appId: "1:845072208903:web:f29ba807314bf737e586a2",
      databaseURL: "https://revi-auto.firebaseio.com",
      storageBucket: "revi-auto.appspot.com",
      // "locationId": "us-central",
      apiKey: "AIzaSyDGruO9cZQ5_mdx0QbdsOTCKrvaReD4b1M",
      authDomain: "revi-auto.firebaseapp.com",
      messagingSenderId: "845072208903",
      measurementId: "G-PZCSNS33LK"
    })),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    provideFirestore(() => getFirestore()),
    provideDatabase(() => getDatabase()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    provideRemoteConfig(() => getRemoteConfig()),
    ScreenTrackingService,
    UserTrackingService,
    provideAnimations(),
    provideHttpClient(),
    provideEnvironmentNgxMask(),
    // { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
};
