// export enum eRRouteEvents {
//   a = 'harshAcceleration',
//   o = 'overSpeed',
//   b = 'harshBraking',
//   co = 'harshCornering',
//   c = 'crash'
// }
export enum eRRouteEvents {
  a = 'harsh.acceleration.event',
  o = 'overspeeding.speed',
  b = 'harsh.braking.event',
  co = 'harsh.cornering.event',
  c = 'crash.event'
}
export enum eRVehicleEvents {
  harshAcceleration = 'harshAcceleration',
  overSpeed = 'overSpeed',
  harshBraking = 'harshBraking',
  harshCornering = 'harshCornering'
}
