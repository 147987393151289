<div class="flex flex-col">
    <!-- Title and close icon -->
    <div class="flex justify-between p-[4px] bg-violet-500">
        <label class="text-white font-bold">Rename Conversation</label>
        <mat-icon class="cursor-pointer text-white hover:text-violet-200" (click)="closeDialog()">close</mat-icon>
    </div>
    <!-- Content -->
    <div class="flex flex-col p-[10px] gap-[10px]">
        <label class="text-sm">Enter new name for the conversation</label>
        <mat-form-field appearance="outline" class="ra-input no-hint">
            <input matInput [(ngModel)]="conversationName" />
        </mat-form-field>

        <!-- Buttons -->
        <div class="flex justify-end gap-[10px]">
            <button mat-raised-button class="!bg-white !text-violet-500 !rounded-md"
                (click)="closeDialog()">Cancel</button>
            <button mat-raised-button class="!bg-violet-500 !text-white !rounded-md" (click)="renameConversation()"
                [disabled]="disableRenameButton()"
                [ngClass]="{'cursor-not-allowed': disableRenameButton()}">Rename</button>
        </div>
    </div>
</div>