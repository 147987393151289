import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GoogleGenerativeAI } from '@google/generative-ai';

@Injectable({ providedIn: 'root' })
export class GeminiService {
  private apiKey = 'AIzaSyCoCwNOYy0HCxOOiwqxwgG5fuZF2VOmQxw'; // Replace with your actual API key

  constructor(private http: HttpClient) { }

  async generateText(prompt: string) {
    const genAI = new GoogleGenerativeAI(this.apiKey);
    const model = genAI.getGenerativeModel({
      model: "gemini-1.5-flash-8b",
      systemInstruction: "Your name is JIM(Joint Information Manager), you are an AI assistant Fleet manager for Downtime Terminator, powered by Revit Auto",
      generationConfig: {
        responseMimeType: "text/plain",
      }
    }); // Choose the desired Gemini model

    const result = await model.generateContent(prompt);

    return result.response.text();
  }
}