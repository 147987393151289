import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { eRPath } from '../../shared/enums/er-routes';

export const guardOnlyLoginUser: CanActivateFn = (_) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  if (authService.isLoggedIn) {
    return true;
  } else {
    return router.navigate([eRPath.signin]);
  }
};

export const guardPrventLogin: CanActivateFn = (_) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  if (authService.isLoggedIn) {
    return router.navigate([eRPath.home]);
  } else {
    return true;
  }
};
