export enum eRFbWhOps {
  less = '<',
  lessEqual = '<=',
  equal = '==',
  greatEqual = '>=',
  notEqual = '!=',
  arrayContains = 'array-contains',
  arrayContainsAny = 'array-contains-any',
  in = 'in',
  nin = 'not-in',
}