export enum eRFeatures {
  fh = 'Fleet Health',
  stat = 'Expense Report',
  users = 'Users',
  insp = 'Inspections',
  rh = 'Request History',
  diagnostics = 'Diagnostics',
  rep = 'Repairs',
  sr = 'Service Records',
  est = 'Estimate',
  rem = 'Reminders',
  cal = 'Calendar Reminder',
  ts = 'Daily Driver Log',
  inv = 'Invoice/Service/Repair History',
  vid = 'Video Training Library',
  mv = 'Vehicle Management',
  it = 'Pre/Post-Trip Inspection Titles',
  dt = 'Diagnostics Titles',
  comp = 'Companies',
  company = 'Company',
  tut = 'Tutorials',
  remp = 'Reminder Profiles',
  sub = 'Subscriptions',
  gps = 'Map',
  obd = 'GPS/OBD2 Devices',
  help = 'Call for help',
  rental = 'Vehicle Rental',
  maint = 'Maintenance Profiles',
  pms = 'Preventive Maintenance',
}
